import React, { useContext } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { componentPagesMenu, institutionDetailsPagesMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';
import AuthContext from '../../../contexts/authContext';

const InstitutionDetailHeader  = () => {
	const { width } = useDeviceScreen();
	const { userData, setUser } = useContext(AuthContext);

	return (
		<Header>
			{ userData.rolId===1?<HeaderLeft>
				<Navigation
					menu={institutionDetailsPagesMenu.institutionDetails.subMenu}
					id={`${institutionDetailsPagesMenu.institutionDetails.id}top-menu`}
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/>
			</HeaderLeft> :''}
			<CommonHeaderRight />
		</Header>
	);
};

export default InstitutionDetailHeader ;
