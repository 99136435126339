// api.js
import axios from 'axios';

const API_BASE_URL = 'https://zappy.lat/backend/v1';

export const loginUser = async (username:string, password:string) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/auth/login`, { username, password });
        return response.data; // Return successful response data
    } catch (error) {
        throw error; // Throw the error for handling in the component
    }
};


export const getMe = async (token:string) => {
    try {
        const config = {
            headers: { Authorization: `Bearer  ${token}` }
          };
          const bodyParameters = {
            key: "value"
          };
        const response = await axios.post(`${API_BASE_URL}/api/auth/me`, bodyParameters,config);
        return response.data; // Return successful response data
    } catch (error) {
        throw error; // Throw the error for handling in the component
    }
};