import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { IUserProps, getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import ZappiLogo from '../../../assets/logos/Zappi_Logo_Completo.jpeg';
import Checks from '../../../components/bootstrap/forms/Checks';
import axios, { AxiosError } from 'axios';
import showNotification from '../../../components/extras/showNotification';
import { getMe, loginUser } from '../../../services/authServices';
import UserImage from '../../../assets/img/wanna/wanna1.png';
import UserImageWebp from '../../../assets/img/wanna/wanna1.webp';
import SERVICES, { IServiceProps } from '../../../common/data/serviceDummyData';


interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	/* if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	} */
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Bienvenidos,</div>
			<div className='text-center h4 text-muted mb-5'>Inicia Sesión para continuar!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}

interface LoginSuccessResponse {
	access_token: string;
	token_type: string;

}

interface LoginErrorResponse {
	message: string;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {

	const { setUser, setUserDataFromApi } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();
	const [signInPassword, setSignInPassword] = useState<boolean>(true);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	

	const navigate = useNavigate();
	/* const handleOnClick = useCallback(() => navigate('/'), [navigate]); */


	// Modifica handleOnClick para redirigir según el rol del usuario
	const handleOnClick = useCallback((id:any)=> {

		console.log('ROLLLLLLLLLLLLL IDDDDDD');
	
			navigate('/');
		
	}, [navigate]);

	const usernameCheck = (username: string) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username: string, password: string) => {
		return getUserDataWithUsername(username).password === password
	};


	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
			checkOne: false,
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			try {
				const loginResponse = await loginUser(values.loginUsername, values.loginPassword);

				try {

					const meResponse = await getMe(loginResponse.access_token);

					const john: IUserProps = {
						id: `${meResponse.id}`,
						username: `${meResponse.email}`,
						name : `${meResponse.first_name}${meResponse.middle_name ? ' ' + meResponse.middle_name : ''}`,
						surname: `${meResponse.last_name} ${meResponse.mother_name}`,
						position: meResponse.role_id === 1 ? 'Administrador' : meResponse.role_id === 2 ? 'Director' : meResponse.role_id === 3 ? 'Profesor' : 'Padre',
						email: `${meResponse.email}`,
						rolId: meResponse.role_id,
						src: UserImage,
						srcSet: UserImageWebp,
						isOnline: true,
						isReply: true,
						color: 'primary',
						services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
						password: '@ABC123',
						token: loginResponse.access_token,
						institution: meResponse.institutions
					};
					if (meResponse) {
						localStorage.removeItem('institutionResponse');
						// Convertir institutionRespose a JSON
						const institutionResponseJSON = JSON.stringify(meResponse.institutions[0]);

						// Guardar en localStorage
						localStorage.setItem('institutionResponse', institutionResponseJSON);
					}
					if (setUser && setUserDataFromApi) {
						console.log('Llenando informacion');
						/* setUserDataFromApi(john); */
						localStorage.setItem('userFromApi', JSON.stringify(john));
						setUser('john'); // Establece el usuario en el contexto tras el inicio de sesión exitoso
						showNotification('Exito', 'El inicio de sesión ha sido exitoso.', 'success');
						handleOnClick(meResponse.role_id);
					}


				} catch (error) {
					if (error instanceof Error) {
						console.error('Error en la api get Me:', error.message);
					} else {
						console.error('Unexpected Error:', error);
					}
				}
				// Redirige a la página principal después del inicio de sesión exitoso
			} catch (error) {
				if (error instanceof Error) {
					showNotification('Credenciales Invalidas', 'El usario o contraseña son incorrectos.', 'danger');
					console.error('Error during login:', error.message);
				} else {
					console.error('Unexpected Error:', error);
				}
			}
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const handleContinue = () => {
		setIsLoading(true);
		setTimeout(() => {
			if (
				!Object.keys(USERS).find(
					(f) => USERS[f].username.toString() === formik.values.loginUsername,
				)
			) {
				formik.setFieldError('loginUsername', 'No such user found in the system.');
			} else {
				setSignInPassword(true);
			}
			setIsLoading(false);
		}, 1000);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={'Login'}
			className={classNames({ 'bg-light': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-4'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												/* 'text-dark': !darkModeStatus, */
												'text-dark': true,
											},
										)}
										aria-label='Zappi'>
										{/* <Logo width={200} /> */}
										<img src={ZappiLogo} alt="Zappi Logo" width={100} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-1 g-3 pb-1 px-1 '>
										<div className='text-center h2 fw-bold mt-3 mb-3'>Iniciar Sesión</div>
										{/* <div className='col'>
											<Button
												color={'light' }
												 isLight={singUpStatus} 
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													
												}}>
												Login
											</Button>
										</div> */}

										{/* 	<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div> */}
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								{/* 	<Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> {USERS.JOHN.username}
										</div>
										<div className='col-12'>
											<strong>Password:</strong> {USERS.JOHN.password}
										</div>
									</div>
								</Alert> */}
								<form className='row g-4'>
									{/* {singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='signup-email'
													isFloating
													label='Your email'>
													<Input type='email' autoComplete='email' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-name'
													isFloating
													label='Your name'>
													<Input autoComplete='given-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-surname'
													isFloating
													label='Your surname'>
													<Input autoComplete='family-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='password'
													/>
												</FormGroup>
											</div>
											 <div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={handleOnClick}>
													Sign Up
												</Button>
											</div> 
										</>
									) : ( */}
									{/* <> */}
									<div className='col-12'>
										<FormGroup
											id='loginUsername'
											isFloating
											label='Email o Identificación'
											className={classNames({
												'd-none': false,
											})}>
											<Input
												autoComplete='username'
												value={formik.values.loginUsername}
												isTouched={formik.touched.loginUsername}
												invalidFeedback={
													formik.errors.loginUsername
												}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>

										</FormGroup>
										<div className='mt-4'></div>
										<FormGroup
											id='loginPassword'
											isFloating
											label='Contraseña'
											className={classNames({
												'd-none': false,

											})}>

											<Input
												type={formik.values.checkOne ? 'text' : 'password'}
												id="loginPassword"
												name="loginPassword" // Opcional: asigna un nombre único si es necesario
												autoComplete="current-password"
												value={formik.values.loginPassword}
												isTouched={formik.touched.loginPassword}
												invalidFeedback={formik.errors.loginPassword}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
											<div className='mt-3 px-2'>
												<Checks
													type='switch'
													id='inlineCheckOne'
													label='Mostrar contraseña'
													name='checkOne'
													onChange={formik.handleChange}
													checked={formik.values.checkOne}
												/>
											</div>
										</FormGroup>
										{/* {signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {formik.values.loginUsername}.
													</div>
												)}
												 */}
									</div>
									<div className='col-12'>
										{/* {!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Continue
													</Button>
												) : ( */}
										<Button
											color='warning'
											className='w-100 py-3'
											onClick={formik.handleSubmit}>
											Iniciar
										</Button>
										{/* )} */}
									</div>
									{/* </>
									) }*/}

									{/* BEGIN :: Social Login */}
									{/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												OR
											</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div>
										</>
									)} */}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
